
.swal-confirm-button {
    background-color: #0bbe05;
    color: #ffffff;
    width: 80px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 5px;

  
    
  }
  
  .swal-cancel-button {
    background-color: #f70808;
    color: #ffffff; 
    margin-left: 80px;
    width: 80px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 5px;
  }
  